import React from "react";
import "../styles/index.sass";
import Footer from "../components/Footer";
import CommitmentCard from "../components/CommitmentCard";
import { title } from "process";
import Carousel from "../components/Carousel";
import Spacer from "../components/Spacer";
import Slider from "../components/ProgressBar";
import BrandCard from "../components/BrandCard";
import useIsMobileViewport from "../hooks/useIsMobileViewport";
import Header from "../components/Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const StylesPage = () => {
    const { isMobile } = useIsMobileViewport();
    return (
        <>
            <Header />
            <Carousel hasArrows isVertical={isMobile}>
                {/* <BrandCard />
                <BrandCard />
                <BrandCard />
                <BrandCard />
                <BrandCard />
                <BrandCard /> */}
            </Carousel>
        </>
    );
};

export default StylesPage;
